<template>
  <div ref="modalRef">
    <a-modal
      :open="show"
      :title="name"
      :footer="null"
      :getContainer="() => $refs.modalRef"
      destroyOnClose
      @cancel="handleClose"
    >
      <div class="content">
        <template v-if="loading">
          <a-spin />
        </template>
        <template v-else>
          <img :src="wxCode" class="wx_code" />
          <a-space>
            <a-button @click="copyToClipboard(miniLink)">复制小程序链接</a-button>
            <a-button @click="downloadFile">下载小程序二维码</a-button>
            <template v-if="isAllowAccess">
              <div class="divider"></div>
              <a-button @click="accessWebLink">访问网页</a-button>
              <a-button @click="copyToClipboard(webLink)">复制网页链接</a-button>
            </template>
          </a-space>
        </template>
      </div>
    </a-modal>
  </div>
</template>

<script setup>
import { ref, getCurrentInstance, computed } from 'vue';

const { proxy } = getCurrentInstance();

const { VUE_APP_WEB_BASE_URL: webUrl } = process.env;

const modalRef = ref();

const props = defineProps({
  name: { type: String },
  path: { type: String },
  query: { type: String },
  isAllowAccess: { type: Boolean, default: false },
});

const show = ref(false),
  loading = ref(false),
  handleOpen = () => {
    show.value = true;
    const getWXLink = getWxUrlLink(),
      getWXCode = getWXACode();
    loading.value = true;
    Promise.all([getWXLink, getWXCode])
      .then((res) => {
        if (res) loading.value = false;
      })
      .catch((err) => {
        throw new Error('Promise 执行错误', err);
      });
  },
  handleClose = () => {
    show.value = false;
    wxCode.value = undefined;
    miniLink.value = undefined;
  },
  handleOk = (e) => {
    show.value = false;
  };
defineExpose({ handleOpen });

const wxCode = ref(),
  getWXACode = () => {
    return new Promise((resolve, reject) => {
      const { path, query } = props;
      const params = { path: `${path}?${query}`, version: process.env.VUE_APP_VERSION };
      proxy.$api
        .getWXACode(params)
        .then((res) => {
          if (res) {
            wxCode.value = res.data;
            resolve(res);
          }
        })
        .catch((err) => {
          return Promise.resolve('getWXACode 无数据');
        });
    });
  },
  miniLink = ref(),
  getWxUrlLink = () => {
    return new Promise((resolve, reject) => {
      const { path, query } = props;
      const params = { path, query, version: process.env.VUE_APP_VERSION };
      proxy.$api
        .getWxURLLink(params)
        .then((res) => {
          if (res) {
            miniLink.value = JSON.parse(res.data).url_link;
            resolve(res);
          }
        })
        .catch((err) => {
          return Promise.resolve('getWxUrlLink 无数据');
        });
    });
  },
  downloadFile = () => {
    let img = new Image();
    img.setAttribute('crossOrigin', 'Anonymous');
    img.onload = function () {
      let canvas = document.createElement('canvas');
      let context = canvas.getContext('2d');
      canvas.width = img.width;
      canvas.height = img.height;
      context.drawImage(img, 0, 0, img.width, img.height);
      canvas.toBlob((blob) => {
        let url = URL.createObjectURL(blob);
        let a = document.createElement('a');
        let event = new MouseEvent('click');
        a.download = `${props.name}.png`;
        a.href = url;
        a.dispatchEvent(event);
        URL.revokeObjectURL(url);
      });
    };
    img.src = wxCode.value + '?v=' + Date.now();
  },
  webLink = computed(() => {
    const { path, query } = props;
    return `${webUrl}/offlineClass/details?${query}`;
  }),
  accessWebLink = () => {
    const a = document.createElement('a');
    a.href = `${webLink.value}`;
    a.target = 'blank';
    a.click();
  },
  copyToClipboard = (txt) => {
    if (navigator.clipboard && window.isSecureContext) {
      return navigator.clipboard.writeText(txt).then(() => {
        proxy.$message.info('复制成功！');
      });
    } else {
      // 创建text area
      const textArea = document.createElement('textarea');
      textArea.value = txt;
      // 使text area不在viewport，同时设置不可见
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      proxy.$message.info('复制成功！');
      return new Promise((res, rej) => {
        // 执行复制命令并移除文本框
        document.execCommand('copy') ? res() : rej();
        textArea.remove();
      });
    }
  };
</script>

<style lang="less" scoped>
:deep(.ant-modal) {
  width: fit-content !important;
  min-width: 557px;

  .ant-modal-header {
    margin-bottom: 40px;
  }

  .content {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .wx_code {
      width: 256px;
      height: 256px;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .divider {
      width: 2px;
      height: 20px;
      background: rgba(#000, 0.15);
    }
  }
}
</style>
