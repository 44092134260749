<template>
  <!-- 日期 -->
  <template v-if="item?.type == 'date'">
    <a-date-picker v-model:value="item.value" />
  </template>

  <!-- 日期+时间 -->
  <template v-if="item?.type == 'dateTime'">
    <a-date-picker v-model:value="item.valueCn" show-time @change="onDateTimeOk" />
  </template>

  <!-- 范围日期 -->
  <template v-else-if="item?.type == 'dateRange'">
    <a-range-picker v-model:value="item.value" :show-time="item.showTime" />
  </template>

  <!-- 输入框 -->
  <template v-else-if="item?.type == 'input'">
    <a-input
      v-model:value="item.value"
      placeholder="请输入"
      class="m-field"
      :label="item?.label"
      :readonly="item?.readonly"
      :style="{ '--color': item?.color || '#000' }"
    ></a-input>
  </template>

  <!-- 数字 -->
  <template v-else-if="item?.type == 'inputNumber'">
    <a-input-number
      v-model:value="item.value"
      placeholder="请输入"
      class="m-field"
      :min="1"
      :label="item?.label"
      :readonly="item?.readonly"
      :style="{ '--color': item?.color || '#000' }"
    ></a-input-number>
  </template>

  <!-- 多行输入 -->
  <template v-else-if="item?.type == 'textarea'">
    <a-textarea
      v-model:value="item.value"
      placeholder="请输入"
      class="m-field"
      :label="item?.label"
      :readonly="item?.readonly"
      :style="{ '--color': item?.color || '#000' }"
    ></a-textarea>
  </template>

  <!-- 选择 -->
  <template v-else-if="item?.type == 'select'">
    <a-select
      v-model:value="item.value"
      :options="item.options"
      :disabled="item.readonly"
      :allowClear="item?.extra?.allowClear"
      placeholder="请选择"
    ></a-select>
  </template>

  <!-- 单选 -->
  <template v-else-if="item?.type == 'radio'">
    <a-radio-group
      v-model:value="item.value"
      :options="item.options"
      :disabled="item.readonly"
      @change="emits('change', { item })"
    ></a-radio-group>
  </template>

  <!-- 多选 -->
  <template v-else-if="item?.type == 'checkbox'">
    <a-checkbox-group v-model:value="item.value" :options="item.options" />
  </template>

  <!-- 开关 -->
  <template v-else-if="item?.type == 'switch'">
    <a-switch v-model:checked="item.value" />
  </template>

  <!-- 图片 -->
  <!-- { key: 'cover', type: 'image', label: '类别封面', value: [], path: 'projects/img/category', maxCount: 3} -->
  <template v-else-if="item?.type == 'image'">
    <a-upload
      v-model:file-list="item.value"
      list-type="picture-card"
      accept="image/*"
      :max-count="item?.maxCount"
      :customRequest="(e) => onUploadImg(e, item)"
    >
      <div v-if="item?.value?.length < item?.maxCount">点击上传</div>
    </a-upload>
  </template>

  <template v-else-if="item?.type == 'table'">
    <a-table
      :rowKey="item.rowKey"
      :pagination="item?.pagination"
      :columns="item?.columns"
      :dataSource="item?.dataSource"
      :row-selection="{
        hideSelectAll: true,
        selectedRowKeys: selectedKeys,
        onChange: onSelectChange,
        onSelect: (selectedRowKeys, selectedRows) => {
          console.log(selectedRowKeys, selectedRows);
          item.value.push(selectedRowKeys[item.rowKey]);
        },
      }"
      @change="(e) => item.handleTableChange(e, item)"
    ></a-table>
  </template>

  <template v-else-if="item?.type == 'addInput'">
    <div class="add-inputs">
      <div class="add-input" v-for="(i, ind) in item.value" :key="i">
        <a-space>
          <a-input
            v-model:value="i.index"
            placeholder="请输入"
            class="m-field m-field-index"
            :style="{ '--color': item?.color || '#000' }"
          ></a-input>
          <a-textarea
            v-model:value="i.text"
            placeholder="请输入"
            class="m-field m-field-text"
            :style="{ '--color': item?.color || '#000' }"
          ></a-textarea>
          <a-upload
            v-model:file-list="i.image.value"
            list-type="picture-card"
            accept="image/*"
            :max-count="i.image.maxCount || 1"
            :customRequest="(e) => onUploadImg(e, i.image)"
          >
            <div v-if="i.image.value.length < i.image.maxCount">点击上传</div>
          </a-upload>
          <a-button
            class="btn-add btn-del"
            @click="onDelete(ind)"
            type="link"
            v-if="ind < item.value.length - 1"
          >
            删除
          </a-button>
          <a-button class="btn-add" @click="onAdd" type="link" v-if="ind == item.value.length - 1">
            添加
          </a-button>
        </a-space>
      </div>
    </div>
  </template>
</template>

<script setup>
import { ref, reactive, getCurrentInstance, watch } from 'vue';
import { qnUpload } from '@/utils';
import dayjs from 'dayjs';

const { proxy } = getCurrentInstance();
const props = defineProps({
  item: { type: Object, default: () => {} },
});
const emits = defineEmits(['change', 'onAdd']);

const onUploadImg = (info, item) => {
  const type = 'img';
  qnUpload(info, item.path, type)
    .then((res) => {
      if (res) {
        if (item.maxCount == 1) {
          item.value = [res];
        } else {
          item.value[item.value.length - 1] = res;
        }
      }
    })
    .catch((err) => {
      proxy.$message.info(err.msg);
    });

  const selectedKeys = ref([]),
    onSelectChange = (selectedRowKeys) => {
      console.log('selectedRowKeys changed: ', selectedRowKeys);
      state.selectedRowKeys = selectedRowKeys;
    };
};

const onDateTimeOk = (e) => {
  const val = new dayjs(e).format('YYYY-MM-DD HH:mm:ss');
  props.item.value = val;
};

const onDelete = (index) => {
    props.item.value.splice(index, 1);
  },
  onAdd = () => {
    emits('onAdd');
  };

watch(
  props.item,
  (data) => {
    emits('change', data);
  },
  { deep: true },
);
</script>

<style lang="less" scoped>
.add-inputs {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;

  .add-input {
    .m-field {
      &-index {
        width: 50px;
      }

      &-text {
        height: 102px;
        resize: none;
        margin-block: 0 8px;
        margin-inline: 0 8px;
      }
    }

    .btn {
      &-add {
        width: 50px;
        padding: 4px 10px;
      }
    }
  }
}

.ant-picker {
  width: 100%;
}
</style>