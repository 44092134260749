<template>
  <div class="search_box">
    <a-form layout="inline" :model="formValue">
      <template v-for="item in formItems" :key="item">
        <a-form-item :label="item.label" v-if="item.isShow ?? true">
          <form-item :item="item" @change="onChange" />
        </a-form-item>
      </template>
      <a-form-item v-if="isShowSearch || isShowReset">
        <a-space>
          <a-button type="primary" @click="onSearch" v-if="isShowSearch">搜索</a-button>
          <a-button type="primary" @click="onReset" ghost v-if="isShowReset">重置</a-button>
        </a-space>
      </a-form-item>
    </a-form>
  </div>
</template>

<script setup>
import { ref, getCurrentInstance } from 'vue';
import { formItem } from '@/component';

const { proxy } = getCurrentInstance();

const emits = defineEmits(['onSubmit', 'loadData']);

const props = defineProps({
  formItems: { type: Array, default: [] },
  isShowSearch: { type: Boolean, default: true },
  isShowReset: { type: Boolean, default: true },
});

const getPlaceholder = ({ type, label }) => {
  return `请${['select', 'cascade'].includes(type) ? '选择' : '输入'}${label}`;
};

const formValue = ref({}),
  onSetForm = () => {
    const obj = {};
    props.formItems.forEach((item) => {
      const { value, type, isShow } = item;
      if ((item.hasOwnProperty('isShow') && isShow) || !item.hasOwnProperty('isShow')) {
        if (['input'].includes(type)) obj[value] = '';
        else if (['select', 'cascade'].includes(type)) obj[value] = [];
      }
    });
    formValue.value = obj;
  };

const onChange = (e) => {
  // props.formItems.forEach((item) => {
  formValue.value[e.key] = e.value;
  // });
  emits('change', e);
};

const onSearch = () => {
    emits('onSubmit', formValue.value);
  },
  onReset = () => {
    onSetForm();
    emits('onSubmit', formValue.value);
  };
</script>

<style lang="less" scoped>
.search_box {
  width: 100%;
  height: 80px;
  background: #fff;
  border-radius: 10px;
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  :deep(.ant-form-item .ant-form-item-control-input-content) {
    min-width: 200px;
  }
}
</style>
