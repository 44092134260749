import { routes } from '../routes';
import BaseLayout from '@/layout/BaseLayout';

export default [
  {
    path: '/',
    redirect: '/index',
  },
  {
    path: '/index',
    name: 'index',
    redirect: '/home',
    component: BaseLayout,
    children: [...routes],
  },
];
