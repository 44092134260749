import { request } from '@/request/request';
import { parameterChange } from '@/utils';

export default {
  // 关联课程
  bindCourse(data) {
    return request({
      url: '/item_course/bindCourse',
      method: 'post',
      data,
    });
  },

  // 已绑定课程
  bundledCourses(params) {
    return request({
      url: '/item_course/bundledCourses',
      method: 'get',
      params,
    });
  },

  // 解绑课程
  unbindCourse(data) {
    return request({
      url: '/item_course/unbindCourse',
      method: 'post',
      data,
    });
  },
};
