import { request } from '@/request/request';
import { parameterChange } from '@/utils';

export default {
  // 新增项目
  itemAdd(data) {
    return request({
      url: '/item_info/itemAdd',
      method: 'post',
      data,
    });
  },

  // 编辑类别
  itemUpdate(data) {
    return request({
      url: '/item_info/itemUpdate',
      method: 'post',
      data,
    });
  },

  // 项目详情
  itemDetail(params) {
    return request({
      url: '/item_info/itemDetail',
      method: 'get',
      params,
    });
  },

  // 类别列表
  itemPageList(params) {
    return request({
      url: '/item_info/itemPageList',
      method: 'get',
      params,
    });
  },

  // 类别下拉列表
  itemList(params) {
    return request({
      url: '/item_info/itemList',
      method: 'get',
      params,
    });
  },

  // 删除类别
  itemDelete(data) {
    return request({
      url: '/item_info/itemDelete',
      method: 'post',
      data,
    });
  },
};
