/*
 * @Date: 2022-07-11 14:27:16
 * @LastEditTime: 2023-05-11 11:09:27
 */

import { message } from 'ant-design-vue';
import dayjs from 'dayjs';

/**
 * @description: 内容拼接
 * @param {*} data
 * @return {*}
 */
export function parameterChange(data) {
  let res = ``;
  for (const key in data) {
    let dataKey = '';
    if (data[key] || data[key] === 0) dataKey = data[key];
    res = `${res}&${key}=${dataKey}`;
  }
  return res.substr(1);
}

// 复制文本
export function copyText(text) {
  return new Promise((resolve) => {
    var textarea = document.createElement('textarea');
    textarea.value = text;
    textarea.style.position = 'fixed'; // 使其在视口之外
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
    resolve();
  });
}

// 获取file名称
export function getFileName(info) {
  if (!info) return;
  return info.split('/').pop();
}

export function getFormData(arr) {
  const temp = {};
  arr.forEach((item) => {
    if (['image'].includes(item.type)) {
      const imgArr = item.value.map((imgItem) => imgItem.url);
      temp[item.key] = imgArr.join(',') || '';
    } else if (['checkbox'].includes(item.type)) {
      temp[item.key] = item.value.join(',') || '';
    } else if (['addInput'].includes(item.type)) {
      const options = {};
      item.value.forEach((option) => {
        let imgs = [];
        option.image.value.forEach((img) => {
          imgs.push(img.url);
        });
        options[option.index] = { text: option.text, image: imgs.join(',') };
      });
      temp[item.key] = JSON.stringify(options);
    } else {
      temp[item.key] = item.value || '';
    }
  });
  return temp;
}

export function handleReview(item) {
  return {
    uid: Date.now() + Math.random(),
    name: getFileName(item),
    status: 'done',
    url: item,
  };
}

export function getFormValue(obj, item) {
  let val, valCn;
  if (obj?.hasOwnProperty(item.key)) {
    if (['image'].includes(item.type)) {
      val = [];
      let arr = obj[item.key]?.split(',');
      arr.forEach((item) => {
        if (item) {
          val.push(handleReview(item));
        }
      });
    } else if (['checkbox'].includes(item.type)) {
      val = obj[item.key]?.split(',');
    } else if (['radio', 'select'].includes(item.type)) {
      val = obj[item.key];
    } else if (['addInput'].includes(item.type)) {
      const temp = [];
      const data = JSON.parse(obj[item.key]);
      Object.keys(data).forEach((key) => {
        let arr = data[key].image?.split(',');
        let imgVal = [];
        arr.forEach((item) => {
          if (item) {
            imgVal.push(handleReview(item));
          }
        });

        const tempObj = {
          index: key,
          text: data[key].text,
          image: {
            maxCount: 1,
            path: 'exam/img/quesBank',
            value: imgVal,
          },
        };
        temp.push(tempObj);
      });
      val = temp;
    } else {
      val = obj[item.key] + '';
    }

    if (['date', 'dateTime'].includes(item.type)) {
      valCn = new dayjs(obj[item.key]);
    }
  } else {
    val = ['image', 'checkbox'].includes(item.type) ? [] : undefined;
  }
  return { val, valCn };
}

export function judgeSubmit(arr) {
  let flag = true;
  for (let item of arr) {
    console.log(item);

    if (
      item.hasOwnProperty('require') &&
      item.require &&
      ((!['checkbox'].includes(item.type) && ['', null, undefined].includes(item.value)) ||
        (['checkbox'].includes(item.type) && !item.value.length))
    ) {
      flag = false;
      message.error(
        `请${['select', 'checkbox', 'checkbox', 'image', 'radio'].includes(item.type) ? '选择' : '填写'}${item.label}！`,
      );
      break;
    }
  }
  return flag;
}

export const findItem = (arr, field, fieldName) => {
  return arr.find((item) => item[field] == fieldName);
};

export const isValidJSON = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};
