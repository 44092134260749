import { request } from '@/request/request';
import { parameterChange } from '@/utils';

export default {
  // 新增类别
  itemClassifyAdd(data) {
    return request({
      url: '/item_classify/classifyAdd',
      method: 'post',
      data,
    });
  },

  // 编辑类别
  itemClassifyUpdate(data) {
    return request({
      url: '/item_classify/classifyUpdate',
      method: 'post',
      data,
    });
  },

  // 类别详情
  itemClassifyDetail(params) {
    return request({
      url: '/item_classify/classifyDetail',
      method: 'get',
      params,
    });
  },

  // 类别列表
  itemClassifyPageList(params) {
    return request({
      url: '/item_classify/classifyPageList',
      method: 'get',
      params,
    });
  },

  // 类别下拉列表
  itemClassifyList(params) {
    return request({
      url: '/item_classify/classifyList',
      method: 'get',
      params,
    });
  },

  // 删除类别
  itemClassifyDelete(data) {
    return request({
      url: '/item_classify/classifyDelete',
      method: 'post',
      data,
    });
  },
};
