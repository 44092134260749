import dayjs from "dayjs";

/**
 * 将时间格式化为时间/月日时间/年月日时间
 * @param {*} time
 * @returns
 */
const formatTime = (time) => {
  time = dayjs(time);
  const isSameYear = dayjs().isSame(time, "year"),
    isSameMonth = dayjs().isSame(time, "month"),
    isSameDate = dayjs().isSame(time, "date");

  if (isSameYear && isSameMonth && isSameDate)
    time = dayjs(time).format("HH:mm");
  else if (isSameYear) time = dayjs(time).format("MM/DD HH:mm");
  else time = dayjs(time).format("YYYY/MM/DD HH:mm");

  return time;
};

export { formatTime };
