import {
  HomeOutlined,
  SettingOutlined,
  ReadOutlined,
  BellOutlined,
  UserOutlined,
  TeamOutlined,
  BookOutlined,
  FileSearchOutlined,
  AppstoreOutlined,
  BarsOutlined,
} from '@ant-design/icons-vue';
import { h } from 'vue';

const source = [
  {
    key: 1,
    icon: HomeOutlined,
    title: '首页',
    name: 'home',
    path: '/home',
    component: 'views/home/index.vue',
  },
  {
    key: 2,
    icon: ReadOutlined,
    title: '课程管理',
    name: 'course',
    path: '/course',
    component: null,
    children: [
      {
        key: '2-1',
        icon: BarsOutlined,
        title: '线上课',
        name: 'online',
        path: '/course/online',
        component: 'views/course/online/index.vue',
      },
      {
        key: '2-2',
        icon: BarsOutlined,
        title: '线下课',
        name: 'offline',
        path: '/course/offline',
        component: 'views/course/offline/index.vue',
      },
      {
        key: '2-3',
        icon: BarsOutlined,
        title: '讲师',
        name: 'lecturer',
        path: '/course/lecturer',
        component: 'views/course/lecturer/index.vue',
      },
    ],
  },
  {
    key: 12,
    icon: ReadOutlined,
    title: '项目管理',
    name: 'projects',
    path: '/projects',
    component: null,
    children: [
      {
        key: '12-1',
        icon: BarsOutlined,
        title: '项目',
        name: 'project',
        path: '/projects/project',
        component: 'views/projects/project/index.vue',
      },
      {
        key: '12-2',
        icon: BarsOutlined,
        title: '类别',
        name: 'classify',
        path: '/projects/classify',
        component: 'views/projects/classify/index.vue',
      },
    ],
  },
  {
    key: 3,
    icon: ReadOutlined,
    title: '活动管理',
    name: 'activityMan',
    path: '/activity',
    redirect: '/activity/index',
    children: [
      {
        key: '3-1',
        icon: BarsOutlined,
        title: '活动',
        path: '/activity/index',
        name: 'activity',
        component: 'views/activity/index/index.vue',
      },
      {
        key: '3-2',
        icon: BarsOutlined,
        title: '关系方',
        path: '/activity/parties',
        name: 'parties',
        component: 'views/activity/parties/index.vue',
      },
    ],
  },
  {
    key: 13,
    icon: ReadOutlined,
    title: '考核管理',
    path: '/exam',
    redirect: '/exam/quesBank',
    children: [
      {
        key: '13-2',
        icon: BarsOutlined,
        title: '考核信息',
        path: '/exam/assessmentInfo',
        name: 'assessmentInfo',
        component: 'views/exam/assessmentInfo/index.vue',
      },
      {
        key: '13-1',
        icon: BarsOutlined,
        title: '题库管理',
        path: '/exam/quesBank',
        name: 'quesBank',
        component: 'views/exam/quesBank/index.vue',
      },
      {
        key: '13-3',
        icon: BarsOutlined,
        title: '成绩管理',
        path: '/exam/score',
        name: 'score',
        component: 'views/exam/score/index.vue',
      },
      {
        key: '13-4',
        icon: BarsOutlined,
        title: '答题记录',
        path: '/exam/record',
        name: 'record',
        component: 'views/exam/record/index.vue',
      },
    ],
  },
  {
    key: 4,
    icon: AppstoreOutlined,
    title: '轮播管理',
    name: 'banner',
    path: '/banner',
    component: 'views/banner/index.vue',
  },
  {
    key: 5,
    icon: FileSearchOutlined,
    title: '搜索管理',
    path: '/user/search',
    name: 'search',
    component: 'views/user/search/index.vue',
  },
  {
    key: 6,
    icon: BellOutlined,
    title: '消息管理',
    path: '/notice',
    name: 'notice',
    component: 'views/notice/index.vue',
  },
  {
    key: 7,
    icon: UserOutlined,
    title: '用户管理',
    path: '/user/userManage',
    name: 'userManage',
    component: 'views/user/manage/index.vue',
  },
  {
    key: 8,
    icon: TeamOutlined,
    title: '团队管理',
    path: '/team',
    name: 'team',
    children: [
      {
        key: '8-1',
        icon: BarsOutlined,
        title: '团队',
        path: '/team/manage',
        name: 'teamManage',
        component: 'views/team/manage/index.vue',
      },
      {
        key: '8-2',
        icon: BarsOutlined,
        title: '班级',
        path: '/team/class',
        name: 'teamClass',
        component: 'views/team/class/index.vue',
      },
      {
        key: '8-3',
        icon: BarsOutlined,
        title: '教师',
        path: '/team/teacher',
        name: 'teamTeacher',
        component: 'views/team/teacher/index.vue',
      },
    ],
  },
  {
    key: 9,
    icon: BookOutlined,
    title: '证书管理',
    path: '/user/certification',
    name: 'certification',
    component: 'views/user/certification/index.vue',
  },

  {
    key: 10,
    icon: SettingOutlined,
    title: '系统设置',
    path: '/system',
    name: 'system',
    children: [
      {
        key: '10-1',
        icon: BarsOutlined,
        title: '管理员',
        path: '/system/administer',
        name: 'administer',
        component: 'views/system/administer/index.vue',
      },
      {
        key: '10-2',
        icon: BarsOutlined,
        title: '角色',
        path: '/system/role',
        name: 'role',
        component: 'views/system/role/index.vue',
      },
      {
        key: '10-3',
        icon: BarsOutlined,
        title: '权限',
        path: '/system/authority',
        name: 'authority',
        component: 'views/system/authority/index.vue',
      },
      {
        key: '10-4',
        icon: BarsOutlined,
        title: '设置',
        path: '/system/setting',
        name: 'setting',
        component: 'views/system/setting/index.vue',
      },
    ],
  },
];

const generateRoutes = (source) => {
  return source.reduce((routes, item) => {
    const route = {
      path: item.path,
      name: item.name,
      meta: { title: item.title, key: item.key, icon: item.icon },
      component: item.component ? () => import(`@/${item.component}`) : null,
    };

    if (item.redirect) {
      route.redirect = item.redirect;
    }

    if (item.children && item.children.length > 0) {
      route.children = generateRoutes(item.children);
    }

    routes.push(route);
    return routes;
  }, []);
};

const generateMenus = (source) => {
  return source.reduce((menus, item) => {
    const menu = {
      key: item.key,
      icon: () => h(item.icon),
      label: item.title,
      name: item.name,
    };

    if (item.children && item.children.length > 0) {
      menu.children = generateMenus(item.children);
    }

    menus.push(menu);
    return menus;
  }, []);
};

let routes = generateRoutes(source);
let menus = generateMenus(source);

export { source, routes, menus };
