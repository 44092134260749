import { request } from '@/request/request';
import { parameterChange } from '@/utils';

export default {
  // 用户名下拉列表
  nameList(params) {
    return request({
      url: 'question_answer/nameList',
      method: 'get',
      params,
    });
  },
  // 题号下拉列表
  numList(params) {
    return request({
      url: 'question_answer/numList',
      method: 'get',
      params,
    });
  },
  // 答题列表
  answerPageList(params) {
    return request({
      url: 'question_answer/answerPageList',
      method: 'get',
      params,
    });
  },
};
