/*
 * @Date: 2022-07-11 14:27:16
 * @LastEditTime: 2024-04-21 11:53:54
 */
import {
  Button,
  DatePicker,
  Form,
  FormItem,
  Input,
  Menu,
  Modal,
  Radio,
  RadioButton,
  RadioGroup,
  Space,
  Switch,
  Table,
  Upload,
  message,
  Tag,
  Select,
  Watermark,
  TimePicker,
  Dropdown,
  Empty,
  Cascader,
  Row,
  Col,
  Descriptions,
  Drawer,
  Spin,
  InputNumber,
  CheckboxGroup,
  Checkbox,
} from 'ant-design-vue';

import 'ant-design-vue/dist/reset.css';

export function lazyUse(app) {
  app.use(Button);
  app.use(Modal);
  app.use(Menu);
  app.use(Form);
  app.use(FormItem);
  app.use(Input);
  app.use(Table);
  app.use(Switch);
  app.use(DatePicker);
  app.use(Space);
  app.use(Upload);
  app.use(RadioGroup);
  app.use(RadioButton);
  app.use(Radio);
  app.use(Tag);
  app.use(Select);
  app.use(Watermark);
  app.use(TimePicker);
  app.use(Dropdown);
  app.use(Empty);
  app.use(Cascader);
  app.use(Row);
  app.use(Col);
  app.use(Descriptions);
  app.use(Drawer);
  app.use(Spin);
  app.use(InputNumber);
  app.use(CheckboxGroup);
  app.use(Checkbox);

  app.config.globalProperties.$message = message;
  app.config.globalProperties.$modal = Modal;
}
