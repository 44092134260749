import { request } from '@/request/request';
import { parameterChange } from '@/utils';

export default {
  // 题目新增
  questionAdd(data) {
    return request({
      url: 'question_info/questionAdd',
      method: 'post',
      data,
    });
  },
  // 题目更新
  questionUpdate(data) {
    return request({
      url: 'question_info/questionUpdate',
      method: 'post',
      data,
    });
  },
  // 题目列表
  questionPageList(data) {
    return request({
      url: 'question_info/questionPageList?' + parameterChange(data),
      method: 'get',
    });
  },
  // 题目详情
  questionDetail(params) {
    return request({
      url: 'question_info/questionDetail',
      method: 'get',
      params,
    });
  },
  // 题目删除
  questionDelete(data) {
    return request({
      url: 'question_info/questionDelete',
      method: 'post',
      data,
    });
  },
};
