import { request } from '@/request/request';
import { parameterChange } from '@/utils';

export default {
  // 考核新增
  examAdd(data) {
    return request({
      url: 'exam_info/examAdd',
      method: 'post',
      data,
    });
  },
  // 考核更新
  examUpdate(data) {
    return request({
      url: 'exam_info/examUpdate',
      method: 'post',
      data,
    });
  },
  // 考核列表
  examPageList(data) {
    return request({
      url: 'exam_info/examPageList?' + parameterChange(data),
      method: 'get',
    });
  },
  // 考核详情
  examDetail(params) {
    return request({
      url: 'exam_info/examDetail',
      method: 'get',
      params,
    });
  },
  examCancel(data) {
    return request({
      url: 'exam_info/examCancel',
      method: 'post',
      data,
    });
  },
  // 考核删除
  examDelete(data) {
    return request({
      url: 'exam_info/examDelete',
      method: 'post',
      data,
    });
  },
  // 考核下拉列表
  examList(params) {
    return request({
      url: 'exam_info/examList',
      method: 'get',
      params,
    });
  },
};
