<template>
  <div class="directory">
    <a-menu
      v-model:openKeys="state.openKeys"
      v-model:selectedKeys="state.selectedKeys"
      mode="inline"
      :items="items"
      @click="handleClick"
    ></a-menu>
  </div>
</template>

<script setup>
import { reactive, getCurrentInstance, computed, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useDirectoryStore } from '@/store/index';

const { proxy } = getCurrentInstance();
const router = useRouter();
const useDirectory = useDirectoryStore();

const items = computed(() => {
  return useDirectory.list;
});

const state = reactive({ selectedKeys: [], openKeys: [], preOpenKeys: [] });
watch(
  () => router.currentRoute.value.name,
  (data) => {
    let route = items.value.find((item) => item.name == data);
    if (route) state.selectedKeys = [route.key];
    else
      items.value.forEach((item) => {
        if (item.children) {
          route = item.children.find((i) => i.name == data);
          if (route) {
            state.selectedKeys = [route.key];
            state.openKeys = [Number(route.key.split('-')[0])];
          }
        }
      });
  },
  { immediate: true, deep: true },
);

const handleClick = (e) => {
  const { item } = e;
  proxy.$router.push({ name: item.name });
};
</script>

<style lang="less" scoped>
.directory {
  width: 200px;
  height: 100%;
  flex-shrink: 0;
  overflow-y: auto;
  border-right: 1px solid #f5f5f5;
}
</style>
