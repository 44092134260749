import { request } from '@/request/request';
import { parameterChange } from '@/utils';

export default {
  // 考核新增
  recordPageList(params) {
    return request({
      url: 'exam_record/recordPageList',
      method: 'get',
      params,
    });
  },
};
