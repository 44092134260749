import { defineStore } from 'pinia';
import { menus } from '@/router/routes/routes';

const useDirectoryStore = defineStore('directory', {
  state: () => ({
    list: [],
    menu: menus,
  }),
  getters: {},
  actions: {
    SET_LIST(list) {
      this.list = list;
    },
  },
});

export default useDirectoryStore;
